import { gql } from '@apollo/client';

export const NOTE_MUTATION = gql`
mutation(
  $instance: ID!
  $author: ID!
  $body: String!
  $id: ID
  ){
    note(input:{
      instance: $instance
      author: $author
      body: $body
      id: $id
    })
    {
      response{
        id
        createdAt
        updatedAt
        body
        author{
          id
          username
        }
      }
      errors{
        field
        messages
      }
  }
}
`;

export const NOTE_DELETE_MUTATION = gql`
mutation(
  $note: ID!){
  noteDelete(input:{
    note: $note})
  {
    response
    errors{
      field
      messages
  }
  }
}
`;
