/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, useMutation } from '@apollo/client';
import type { DefaultContext, MutationFunctionOptions, OperationVariables } from '@apollo/client';
import {
  IconExternalLink, IconShield, IconLayoutGrid, IconDatabase, IconClock,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useState, useEffect } from 'react';
import status from 'statuses';
import { ContainerType, InstanceType } from '../../../../__generated__/graphql';
import {
  CONTAINER_GULP_BUILD_MUTATION, CONTAINER_STOP_MUTATION,
  CONTAINER_CANCEL_CRON_MUTATION, CONTAINER_GULP_BUILD_DEVELOPMENT_MUTATION,
  CONTAINER_NORMALIZE_PERMISSIONS_MUTATION,
  CONTAINER_START_CRON_MUTATION, CONTAINER_START_MUTATION,
} from '../../../../api/mutations/containers';
import {
  INSTANCE_BACKUP_MUTATION, INSTANCE_REFRESH_MUTATION,
  INSTANCE_START_MUTATION, INSTANCE_STOP_MUTATION, INSTANCE_REBUILD_MUTATION,
} from '../../../../api/mutations/instances';
import { ACTION_LOGS_QUERY } from '../../../../api/queries/actionLogs';
import {
  DBA_ENABLED_INSTANCES_QUERY, INSTANCE_QUERY,
  INSTANCES_QUANTITY_QUERY, PAGINATED_INSTANCES_QUERY,
} from '../../../../api/queries/instances';
import { CONTAINER_TYPE, ACTION_EVENT, INSTANCE_TYPE } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import {
  classNames,
  getHTTPResponseDotColorClass,
  getSecureStateColorClass,
  formatTimestamp,
  getLabelFromEnumValue,
} from '../../../../helpers/utils';
import { setSuccessAlert, setInfoAlert, setErrorAlert } from '../../../../redux/alertSlice';
import {
  GULP_BUILD_CONFIRMATION, STOP_INSTANCE_CONFIRMATION, START_INSTANCE_CONFIRMATION,
  REBUILD_CONFIRMATION, STOP_DBA_CONFIRMATION, START_DBA_CONFIRMATION,
  BACKUP_CONFIRMATION, REFRESH_CONFIRMATION, NORMALIZE_PERMISSIONS_CONFRIMATION,
  CANCEL_CRON_CONFRIMATION, START_CRON_CONFRIMATION, GULP_BUILD_DEVELOPMENT_CONFIRMATION,
} from '../../../../redux/constants';
import { openConfirmationModal, resetConfirmationModal } from '../../../../redux/dashboardSlice';
import ActionOptions from './ActionOptions';
import { ShowPHPVersionButton, ShowDbVersionButton } from './buttons';
import ContainerCard from './ContainerCard';
import ContainerTabs from './ContainerTabs';
import PinAction from './PinAction';
import UpdateInstance from './UpdateInstance';

export type ActionOptionType = typeof GULP_BUILD_CONFIRMATION
  | typeof STOP_INSTANCE_CONFIRMATION
  | typeof START_INSTANCE_CONFIRMATION
  | typeof REBUILD_CONFIRMATION
  | typeof STOP_DBA_CONFIRMATION
  | typeof START_DBA_CONFIRMATION
  | typeof BACKUP_CONFIRMATION
  | typeof REFRESH_CONFIRMATION
  | typeof NORMALIZE_PERMISSIONS_CONFRIMATION
  | typeof CANCEL_CRON_CONFRIMATION
  | typeof START_CRON_CONFRIMATION
  | typeof GULP_BUILD_DEVELOPMENT_CONFIRMATION;

export interface ActionOptionParams {
  option: ActionOptionType,
  title: string,
}

interface Props {
  item: InstanceType,
}

function Overview(props: Props) {
  const { item } = props;
  const [currentContainer, setCurrentContainer] = useState(CONTAINER_TYPE.MODX);
  const [actionOption, setActionOption] = useState<ActionOptionType | null>(null);
  const [isRefreshInstanceTrigered, setIsRefreshInstanceTrigered] = useState(false);
  const instancesQueryAttributesState = useAppSelector((state) => state.instancesQueryAttributes);
  const instancesState = useAppSelector((state) => state.instances);
  const confirmationModal = useAppSelector((state) => state.dashboard.confirmationModal);
  const dispatch = useAppDispatch();
  const showInstanceCard = !instancesState.showUpdate;

  const modxContainer = item.containers.find(
    (container) => container.containerType === CONTAINER_TYPE.MODX,
  );

  const dbaContainer = item.containers.find(
    (container) => container.containerType === CONTAINER_TYPE.DB_ADMIN_PANEL,
  );

  const overviewRefetchQueries = [
    { query: INSTANCE_QUERY, variables: { instance: item.id } },
    { query: ACTION_LOGS_QUERY, variables: { instance: item.id } },
  ];

  const instanceStartStopMutationRefetchQueries = [
    { query: INSTANCE_QUERY, variables: { instance: item.id } },
    {
      query: PAGINATED_INSTANCES_QUERY,
      variables: {
        page: instancesQueryAttributesState.page,
        name: instancesQueryAttributesState.name,
        category: INSTANCE_TYPE.ACTIVE,
      },
    },
    {
      query: PAGINATED_INSTANCES_QUERY,
      variables: {
        page: instancesQueryAttributesState.page,
        name: instancesQueryAttributesState.name,
        category: INSTANCE_TYPE.EXITED,
      },
    },
    {
      query: PAGINATED_INSTANCES_QUERY,
      variables: {
        page: instancesQueryAttributesState.page,
        name: instancesQueryAttributesState.name,
        category: INSTANCE_TYPE.ERROR,
      },
    },
    {
      query: PAGINATED_INSTANCES_QUERY,
      variables: {
        page: instancesQueryAttributesState.page,
        name: instancesQueryAttributesState.name,
        category: INSTANCE_TYPE.HIGH,
      },
    },
    { query: INSTANCES_QUANTITY_QUERY },
    { query: ACTION_LOGS_QUERY, variables: { instance: item.id } },
  ];

  const containerStartStopMutationRefetchQueries = [
    { query: INSTANCE_QUERY, variables: { instance: item.id } },
    { query: ACTION_LOGS_QUERY, variables: { instance: item.id } },
    { query: DBA_ENABLED_INSTANCES_QUERY },
  ];

  const instanceIdVariable = {
    instance: item.id,
  };

  const modxContainerVariable = {
    container: modxContainer?.id,
  };

  const dbaContainerVariable = {
    container: dbaContainer?.id,
  };

  const [containerGulpBuildMutation,
    { },
  ] = useMutation(CONTAINER_GULP_BUILD_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [containerGulpBuildDevelopmentMutation,
    { },
  ] = useMutation(CONTAINER_GULP_BUILD_DEVELOPMENT_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [instanceStopMutation,
    { },
  ] = useMutation(INSTANCE_STOP_MUTATION, {
    refetchQueries: instanceStartStopMutationRefetchQueries,
  });

  const [instanceStartMutation,
    { },
  ] = useMutation(INSTANCE_START_MUTATION, {
    refetchQueries: instanceStartStopMutationRefetchQueries,
  });

  const [instanceRebuildMutation,
    { },
  ] = useMutation(INSTANCE_REBUILD_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [containerStopMutation,
    { },
  ] = useMutation(CONTAINER_STOP_MUTATION, {
    refetchQueries: containerStartStopMutationRefetchQueries,
  });

  const [containerStartMutation,
    { },
  ] = useMutation(CONTAINER_START_MUTATION, {
    refetchQueries: containerStartStopMutationRefetchQueries,
  });

  const [instanceBackupMutation,
    { },
  ] = useMutation(INSTANCE_BACKUP_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [instanceRefreshMutation,
    { },
  ] = useMutation(INSTANCE_REFRESH_MUTATION, {
    refetchQueries: [
      { query: INSTANCE_QUERY, variables: { instance: item.id } },
    ],
  });

  const [containerNormalizePermissionsMutation,
    { },
  ] = useMutation(CONTAINER_NORMALIZE_PERMISSIONS_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [containerCancelCronMutation,
    { },
  ] = useMutation(CONTAINER_CANCEL_CRON_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const [containerStartCronMutation,
    { },
  ] = useMutation(CONTAINER_START_CRON_MUTATION, {
    refetchQueries: overviewRefetchQueries,
  });

  const actionOptionHandler = (optionParams: ActionOptionParams) => {
    setActionOption(optionParams.option);
    dispatch(openConfirmationModal({
      title: optionParams.title,
    }));
  };

  function executeChosenActionOption(
    currentMutation: (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined) => Promise<any>,
    variables: { instance: string | undefined } | { container: string | undefined },
    infoAlertMessage: string[],
  ) {
    setIsRefreshInstanceTrigered(false);

    currentMutation({
      variables: variables,
    });

    dispatch(resetConfirmationModal());
    dispatch(setInfoAlert({
      messages: infoAlertMessage,
      instanceId: item.id,
    }));
  }

  useEffect(() => {
    if (!isRefreshInstanceTrigered
      && item.latestActionLog
      && !item.latestActionLog.isDismissed) {
      if (item.latestActionLog.event === ACTION_EVENT.SUCCESS) {
        dispatch(setSuccessAlert({
          messages: [`${getLabelFromEnumValue(item.latestActionLog.actionType)} is successful`],
          instanceId: item.id,
        }));
      }
      if (item.latestActionLog.event === ACTION_EVENT.EXECUTION) {
        dispatch(setInfoAlert({
          messages: [`Processing ${getLabelFromEnumValue(item.latestActionLog.actionType)}`],
          instanceId: item.id,
        }));
      }
      if (item.latestActionLog.event === ACTION_EVENT.FAILURE) {
        dispatch(setErrorAlert({
          messages: [`${getLabelFromEnumValue(item.latestActionLog.actionType)} failed to complete`],
          instanceId: item.id,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, item]);

  useEffect(() => {
    if (confirmationModal.actionApproved) {
      switch (actionOption) {
        case GULP_BUILD_CONFIRMATION:
          executeChosenActionOption(
            containerGulpBuildMutation,
            modxContainerVariable,
            ['Processing Gulp Build'],
          );
          break;

        case GULP_BUILD_DEVELOPMENT_CONFIRMATION:
          executeChosenActionOption(
            containerGulpBuildDevelopmentMutation,
            modxContainerVariable,
            ['Processing Gulp Development Build'],
          );
          break;

        case STOP_INSTANCE_CONFIRMATION:
          executeChosenActionOption(
            instanceStopMutation,
            instanceIdVariable,
            ['Stopping the Instance'],
          );
          break;

        case START_INSTANCE_CONFIRMATION:
          executeChosenActionOption(
            instanceStartMutation,
            instanceIdVariable,
            ['Starting the Instance'],
          );
          break;

        case REBUILD_CONFIRMATION:
          executeChosenActionOption(
            instanceRebuildMutation,
            instanceIdVariable,
            ['Processing Rebuild'],
          );
          break;

        case STOP_DBA_CONFIRMATION:
          executeChosenActionOption(
            containerStopMutation,
            dbaContainerVariable,
            ['Disabling DBA GUI'],
          );
          break;

        case START_DBA_CONFIRMATION:
          executeChosenActionOption(
            containerStartMutation,
            dbaContainerVariable,
            ['Enabling DBA GUI'],
          );
          break;

        case BACKUP_CONFIRMATION:
          executeChosenActionOption(
            instanceBackupMutation,
            instanceIdVariable,
            ['Processing Backup'],
          );
          break;

        // We make individual case for 'Refresh instance data' to separate 
        // his alerts from others, which displayed in Action Log.
        case REFRESH_CONFIRMATION:
          setIsRefreshInstanceTrigered(true);
          instanceRefreshMutation({
            variables: instanceIdVariable,
            onCompleted: () => {
              dispatch(setSuccessAlert({
                messages: ['Instance Data was refreshed'],
                instanceId: item.id,
              }));
            },
          });
          dispatch(resetConfirmationModal());
          dispatch(setInfoAlert({
            messages: ['Refreshing Data'],
            instanceId: item.id,
          }));
          break;

        case NORMALIZE_PERMISSIONS_CONFRIMATION:
          executeChosenActionOption(
            containerNormalizePermissionsMutation,
            modxContainerVariable,
            ['Processing Permissions Normalization'],
          );
          break;

        case CANCEL_CRON_CONFRIMATION:
          executeChosenActionOption(
            containerCancelCronMutation,
            modxContainerVariable,
            ['Disabling Cron Task Support'],
          );
          break;

        case START_CRON_CONFRIMATION:
          executeChosenActionOption(
            containerStartCronMutation,
            modxContainerVariable,
            ['Enabling Cron Task Support'],
          );
          break;
      }

      setActionOption(null);
    }

    if (confirmationModal.actionDeclined) {
      setActionOption(null);
      dispatch(resetConfirmationModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionOption, confirmationModal.actionApproved, confirmationModal.actionDeclined, dispatch]);

  return (
    <div className="py-6 space-y-6 sm:py-0 sm:space-y-0">
      {!showInstanceCard ? (
        <UpdateInstance />
      ) : (
        <>
          <div className="px-4 sm:space-y-0 sm:gap-4 sm:px-6 sm:py-4">
            <div className="flex items-center">
              <div className="cursor-pointer">
                <div>
                  {item.responseCode && (
                    <span className={classNames(getHTTPResponseDotColorClass(item, false), 'inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white')}>
                      {`HTTP Response: ${item.responseCode} ${status(item.responseCode)}`}
                    </span>
                  )}
                  <span className={classNames(getSecureStateColorClass(item.isHttps), 'inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white')}>
                    {item.isHttps ? 'Secure' : 'Not Secure'}
                  </span>
                  {item.cronEnabled && (
                    <span className="bg-orange-500 inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white">
                      CRON
                    </span>
                  )}
                  {item.dbaEnabled && (
                    <span className="bg-orange-500 inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-white">
                      DBA
                    </span>
                  )}
                </div>

                <div className="flex items-center cursor-pointer">
                  <span className="text-lg">
                    {item.mainDomain}
                  </span>
                  <Tippy content="Go to Site">
                    <a target="_blank" href={`http://${item.mainDomain}`} rel="noopener noreferrer" className="hidden sm:flex icon">
                      <IconExternalLink className="ml-1 h-5 w-5  text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                    </a>
                  </Tippy>
                  <Tippy content="Go to Manager Panel">
                    <a target="_blank" href={`http://${item.mainDomain}/luxadmin/`} rel="noopener noreferrer" className="hidden sm:flex icon">
                      <IconShield className="ml-1 h-5 w-5  text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                    </a>
                  </Tippy>
                  {item.dbaEnabled && (
                    <Tippy content="Go to Database Admin Panel">
                      <a target="_blank" href={`http://dba.${item.projectName}/`} rel="noopener noreferrer" className="hidden sm:flex icon">
                        <IconDatabase className="ml-1 h-5 w-5  text-slate-400 hover:text-slate-600 cursor-pointer" aria-hidden="true" />
                      </a>
                    </Tippy>
                  )}
                </div>
              </div>
              <div className="ml-auto flex cursor-pointer">
                <div className="hidden sm:flex">
                  <PinAction item={item} />
                </div>
                {item.latestActionLog && item.latestActionLog.event === ACTION_EVENT.EXECUTION
                  ? (
                    <Tippy content="Processing">
                      <div
                        className="hidden sm:flex"
                        role="button"
                        tabIndex={0}
                      >
                        <IconClock className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                      </div>
                    </Tippy>
                  )
                  : (
                    <>
                      {item?.flavor?.name 
                        && ['Tailwind V3', 'Tailwind V2'].includes(item?.flavor?.name)
                        && item.flavor.isActive
                        && (
                          <Tippy content="Gulp Build">
                            <div
                              className="hidden sm:flex"
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                setActionOption(GULP_BUILD_CONFIRMATION);
                                dispatch(openConfirmationModal({
                                  title: 'Confirm Gulp Build',
                                }));
                              }}
                            >
                              <IconLayoutGrid className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                            </div>
                          </Tippy>
                        )}
                      <Tippy content="Actions">
                        <div>
                          <ActionOptions
                            item={item}
                            actionOptionHandler={actionOptionHandler}
                          />
                        </div>
                      </Tippy>
                    </>
                  )}
              </div>
            </div>
          </div>

          <div className="border-y border-slate-200 px-4 py-3 sm:px-6">
            <dl className="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
              <div className="col-span-2 sm:col-span-1">
                <dt className=" text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Site Name</dt>
                <dd className="text-md sm:text-sm sm:font-medium text-slate-900">{item.siteName}</dd>
              </div>
              <div className="col-span-2">
                <dt className=" text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Project Name</dt>
                <dd className=" sm:font-medium text-md sm:text-sm text-slate-900">{item.projectName}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <dt className=" text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Server</dt>
                <dd className="sm:font-medium text-md sm:text-sm text-slate-900">{item?.server?.serverLabel?.toUpperCase()}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <dt className="text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Created At</dt>
                <dd className="sm:font-medium text-md sm:text-sm text-slate-900">{formatTimestamp(item.createdAt)}</dd>
              </div>
              <div className="col-span-2 sm:col-span-1">
                <dt className="text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Flavor</dt>
                <dd className="sm:font-medium text-md sm:text-sm text-slate-900">{item?.flavor?.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-slate-500">PHP Version</dt>
                <dd className="font-medium text-sm text-slate-900"><ShowPHPVersionButton /></dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-xs font-medium text-slate-500">DB Version</dt>
                <dd className="font-medium text-sm text-slate-900"><ShowDbVersionButton /></dd>
              </div>
              {!!item?.aliasDomains?.length && (
                <div className="col-span-2 sm:col-span-3">
                  <dt className="text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Alias Domains</dt>
                  <dd className="font-medium text-sm text-slate-900">
                    {item?.aliasDomains?.map((domain) => (
                      <a
                        href={`http://${domain?.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={domain?.id}
                        className="bg-slate-200 inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-slate-500"
                      >
                        {domain?.name}
                      </a>
                    ))}
                  </dd>
                </div>
              )}
              {!!item?.redirectDomains?.length && (
                <div className="col-span-2 sm:col-span-3">
                  <dt className="text-sm sm:text-xs font-semibold sm:font-medium text-slate-500">Redirect Domains</dt>
                  <dd className="font-medium text-sm text-slate-900">
                    {item?.redirectDomains?.map((domain) => (
                      <a
                        href={`http://${domain?.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={domain?.id}
                        className="bg-slate-200 inline-flex items-center px-2 py-0.5 mr-2 my-0.5 rounded text-xs font-medium text-slate-500"
                      >
                        {domain?.name}
                      </a>
                    ))}
                  </dd>
                </div>
              )}
            </dl>
          </div>

          <div className="hidden sm:block">
            <ContainerTabs
              currentContainer={currentContainer}
              setCurrentContainer={setCurrentContainer}
              containers={item.containers}
            />
            {item && (
              <ContainerCard item={item.containers
                .find((container) => container.containerType === currentContainer) as ContainerType}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Overview;
