import { gql } from '@apollo/client';

export const VERSION_CONTROL_STORE_LOCAL_CHANGES_MUTATION = gql`
mutation(
  $instance: ID!
  ){
    versionControlStoreLocalChanges(input:{
      instance: $instance
    })
    {
      response
      errors{
        field
        messages
      }
  }
}
`;

export const VERSION_CONTROL_APPLY_REMOTE_CHANGES_MUTATION = gql`
mutation(
  $instance: ID!
  ){
    versionControlApplyRemoteChanges(input:{
      instance: $instance
    })
    {
      response
      errors{
        field
        messages
      }
  }
}
`;

export const VERSION_CONTROL_RESET_LOCAL_CHANGES_MUTATION = gql`
mutation(
  $instance: ID!
  ){
    versionControlResetLocalChanges(input:{
      instance: $instance
    })
    {
      response
      errors{
        field
        messages
      }
  }
}
`;
