import { gql } from '@apollo/client';

export const BACKUPS_QUERY = gql`
query($instance: ID!){
  backups(
    instance: $instance){
      errors{
        field
        messages
      }
      response{
        id
        createdAt
        fileName
        size
        fileType
      }
  }
}
`;
