import { gql } from '@apollo/client';

export const INSTANCE_CREATE_MUTATION = gql`
mutation(
  $mainDomain: String!,
  $siteName: String!,
  $flavor: ID!, 
  $server: ID!, 
  $aliasDomainsList: [String],
  $redirectionDomainsList: [String],
  ){
  instanceCreate(input:{
    mainDomain: $mainDomain
    siteName: $siteName
    flavor: $flavor
    server: $server
    aliasDomainsList: $aliasDomainsList
    redirectionDomainsList: $redirectionDomainsList
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_UPDATE_MUTATION = gql`
mutation(
  $instance: ID!,
  $siteName: String,
  $mainDomain: String,
  $aliasDomainsList: [String],
  $redirectionDomainsList: [String],
  ){
  instanceUpdate(input:{
    instance: $instance
    siteName: $siteName
    mainDomain: $mainDomain
    aliasDomainsList: $aliasDomainsList
    redirectionDomainsList: $redirectionDomainsList
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_REBUILD_MUTATION = gql`
mutation(
  $instance: ID!){
  instanceRebuild(input:{
    instance: $instance})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_REFRESH_MUTATION = gql`
mutation(
  $instance: ID!){
  instanceRefresh(input:{
    instance: $instance})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_STOP_MUTATION = gql`
mutation(
  $instance: ID!,
  ){
  instanceStop(input:{
    instance: $instance
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_START_MUTATION = gql`
mutation(
  $instance: ID!,
  ){
  instanceStart(input:{
    instance: $instance
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const PIN_INSTANCE_MUTATION = gql`
mutation(
  $instance: ID!,
  ){
    pinInstance(input:{
      instance: $instance
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const UNPIN_INSTANCE_MUTATION = gql`
mutation(
  $instance: ID!,
  ){
    unpinInstance(input:{
      instance: $instance
  })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const INSTANCE_BACKUP_MUTATION = gql`
mutation(
  $instance: ID!){
  instanceBackup(input:{
    instance: $instance})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;
