import { gql } from '@apollo/client';

export const ACTIVATE_MODX_USERS_MUTATION = gql`
mutation(
  $instance: ID!
  $users: [String!]!
  ){
  activateModxUsers(input: {
      instance: $instance
      users: $users
    })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const DEACTIVATE_MODX_USERS_MUTATION = gql`
mutation(
  $instance: ID!
  $users: [String!]!
  ){
  deactivateModxUsers(input: {
      instance: $instance
      users: $users
    })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const REMOVE_MODX_USERS_MUTATION = gql`
mutation(
  $instance: ID!
  $users: [String!]!
  ){
  removeModxUsers(input: {
      instance: $instance
      users: $users
    })
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const GLOBAL_ACTIVATE_MODX_USERS_MUTATION = gql`
mutation(
  $users: [String!]!
  ){
  globalActivateModxUsers(input: {users: $users})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;

export const GLOBAL_DEACTIVATE_MODX_USERS_MUTATION = gql`
mutation(
  $users: [String!]!
  ){
  globalDeactivateModxUsers(input: {users: $users})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;
export const GLOBAL_REMOVE_MODX_USERS_MUTATION = gql`
mutation(
  $users: [String!]!
  ){
  globalRemoveModxUsers(input: {users: $users})
  {
    response
    errors{
      field
      messages
    }
  }
}
`;
