import { gql } from '@apollo/client';

export const LATEST_COMMITS_QUERY = gql`
query($instance: ID!){
  latestCommits(
    instance: $instance){
      errors{
        field
        messages
      }
      response{
        id
        title
        authorName
        createdAt
      }
  }
}
`;
