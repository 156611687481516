import { gql } from '@apollo/client';

export const FONTS_SET_MUTATION = gql`
mutation(
  $instance: ID!
  $fonts: [InputFontType!]!
  ){
    fontsSet(input:{
      instance: $instance
      fonts: $fonts
    })
    {
      response
      errors{
        field
        messages
      }
  }
}
`;
