import { gql } from '@apollo/client';

export const FLAVORS_QUERY = gql`
  query{
    flavors{
      errors{
        field
        messages
      }
      response{
        id
        name
      }
    }
  }
`;
