import { gql } from '@apollo/client';

export const NPM_INSTALL_MUTATION = gql`
mutation(
  $instance: ID!
  ){
    npmInstall(input:{
      instance: $instance
    })
    {
      response
      errors{
        field
        messages
      }
  }
}
`;
