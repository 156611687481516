import { gql } from '@apollo/client';

export const SERVERS_QUERY = gql`
  query{
    servers{
      id
      domain
      isHost
      instancesLeft
      serverLabel
    }
  }
`;
