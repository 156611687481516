import { gql } from '@apollo/client';

export const AVAILABLE_FONTS_QUERY = gql`
  query{
    availableFonts{
      errors{
        field
        messages
      }
      response
    }
  }
`;

export const CURRENT_FONTS_QUERY = gql`
query($instance: ID!){
  currentFonts(
    instance: $instance){
      errors{
        field
        messages
      }
      response{
        id
        category
        family
        file
        variant
        version
        isDefault
      }
  }
}
`;
